@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro);
* {
  scroll-margin-top: 40px;
}
body {
  font-family: "Helvetica", sans-serif !important;
  font-size: 18px !important;
  overflow-x: hidden;
}
.navbar > .container-fluid {
  padding: 0 20px;
}
.nav-link:hover,
.nav-link:focus {
  color: #4da045 !important;
}
.navbar li.nav-item {
  width: 120px;
  text-align: center;
}
.navbar .navbar-nav {
  flex-direction: row;
  width: 100%;
  justify-content: space-evenly;
  /* margin-top: 34px; */
  margin-bottom: -10px;
}
/* .navbar-collapse {
    justify-content: flex-start;
} */
.logo {
  width: 250px;
}
nav {
  box-shadow: 1px 1px 3px #ccc;
  background: #fff;
}
/* .navbar-collapse {
    justify-content: flex-end;
} */
/* .nav-item svg {
    width: 22px;
    height: 22px;
} */
a.nav-link {
  color: #686868;
  font-size: 18px;
  cursor: pointer;
}
/* .right-nav li.nav-item {
    width: auto;
} */
.banner {
  background: linear-gradient(rgb(184, 215, 184), #f6f7d4);
  padding: 40px 20px 50px;
}
.banner h1 {
  color: rgb(2, 65, 2) !important;
  font-size: 55px;
  font-weight: bolder;
  animation: text 2.5s 1;
  line-height: 1;
}
.banner h6 {
  color: #707070;
  font-size: 20px;
  font-weight: 600;
}
@keyframes text {
  0% {
    color: black;
    margin-bottom: -10px;
  }
  30% {
    letter-spacing: 10px;
    margin-bottom: -10px;
  }
}
#productImageSection {
  padding: 20px 20px 10px;
}
.productImage .organisation-logo {
  width: 65px;
  height: 65px;
  position: absolute;
  left: 30px;
  object-fit: contain;
}
#productSection {
  background-image: url("/public/images/background/product-mobile.png");
  background-repeat: no-repeat;
  background-size: cover;
  padding: 20px 20px 50px;
}
.purchase-btn {
  width: 100%;
  background: #4da045 !important;
  border-color: #4da045 !important;
  color: #fff;
  padding: 10px !important;
  -moz-transition: box-shadow 0.5s ease-in 0.1s;
  -o-transition: box-shadow 0.5s ease-in 0.1s;
  -webkit-transition: box-shadow 0.5s ease-in;
  transition: box-shadow 0.5s ease-in 0.1s;
  -moz-box-shadow: inset 0 0 0 0em #bed904;
  -webkit-box-shadow: inset 0 0 0 0em #bed904;
  box-shadow: inset 0 0 0 0em #bed904;
  font-weight: 600 !important;
  font-size: 17px !important;
  letter-spacing: 1px;
}
.purchase-btn:hover {
  -moz-box-shadow: inset 40em 0 0 0 #4da045;
  -webkit-box-shadow: inset 40em 0 0 0 #4da045;
  box-shadow: inset 40em 0 0 0 #4da045;
  color: #fff !important;
}
.purchase-btn .placeholder {
  background-color: #999;
  border-color: #999;
}
.btn_group {
  padding: 8px 0px;
  display: flex;
  flex: 1;
  justify-content: space-between;
  /* align-items: flex-start; */
}
.btn_group .btn {
  width: 100%;
  padding: 10px 6px;
  font-size: 17px;
  font-weight: 600;
  color: #4da045;
  background: #fff;
  border: 2px solid #4da045;
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn_group .btn:hover,
.btn_group .btn:focus {
  background: #4da045;
  color: #fff;
  border-color: #4da045;
}
.cart-icon {
  margin-right: 6px;
  font-size: 20px;
}
.ar-icon {
  margin-right: 5px;
  font-size: 21px;
}
.immersive-icon {
  margin-right: 6px;
  font-size: 20px;
}
.product .col-sm-12 {
  margin-bottom: 20px;
}
.product .col-sm-12:last-child {
  margin-bottom: 0;
}
.product h2 {
  font-family: "Helvetica-Bold", "Helvetica", sans-serif;
  font-weight: 700;
  font-size: 24px;
  color: #024102;
  letter-spacing: -0.03em;
}
.info h1 {
  color: #4da045 !important;
  animation: text 2.5s 1;
  font-size: 4rem;
  margin-bottom: 40px;
  letter-spacing: -0.02em;
  /* line-height: 5rem; */
  /* font-weight: 600; */
}
.info .info-img {
  width: 24px;
  height: 24px;
  margin: 0 18px 3px 0;
}
.info {
  /* background: linear-gradient(rgb(241 241 241), #f6f7d4); */
  /* background: #3b3b3b; */
  padding: 60px 20px;
  background-image: url("/public/images/background/info-mobile.png");
  background-repeat: no-repeat;
  background-size: cover;
}
#infoSection .mx-auto {
  align-items: center;
}
.info .youtube-video {
  width: 100%;
  height: 250px;
  margin-bottom: 24px;
}
.info .gallery-active-img {
  background-color: #000;
}
.info .gallery-active-img img {
  width: 100%;
  height: 250px;
  object-fit: contain;
}
.info .gallery-thumbnail {
  display: flex;
  gap: 0.5rem;
  overflow-x: auto;
  margin-top: 8px;
}
.info .gallery-thumbnail img {
  object-fit: cover;
}
/* .info img .img-fluid {
    margin: 20px auto 0 auto;
    display: block;
} */
/*Trace css*/
#traceSection {
  background-image: url("/public/images/background/footprint-mobile.png");
  background-repeat: no-repeat;
  background-size: cover;
  padding: 60px 20px 40px;
}
#traceSection .row {
  align-items: center;
}
.timeline {
  position: relative;
  padding-left: 0;
  left: 40px;
}
.timeline:before {
  background-color: #d5d5d5;
  content: "";
  margin-left: -1px;
  position: absolute;
  top: 0;
  left: 93px;
  width: 2px;
  height: 100%;
}
.timeline-event {
  position: relative;
  list-style: none;
  display: flex;
}
.timeline-event:hover .timeline-event-icon {
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  background-color: #4da045;
}
.timeline-event:hover .timeline-event-thumbnail {
  -moz-box-shadow: inset 40em 0 0 0 #4da045;
  -webkit-box-shadow: inset 40em 0 0 0 #4da045;
  box-shadow: inset 40em 0 0 0 #4da045;
}
.timeline-event-copy {
  position: relative;
  width: 60%;
}
.timeline-event-copy span {
  display: flex;
  font-size: 12px;
  padding: 2px 8px 2px 8px;
  background: #eaeaea;
  width: 75px;
  margin-top: -10px;
  align-items: center;
  justify-content: center;
}
.timeline-event-copy img {
  width: 20px;
  margin-right: 5px;
}
.timeline-event-copy h3 {
  font-size: 1.75em;
}
.timeline-event-copy h4 {
  font-size: 1.2em;
  margin-bottom: 1.2em;
}
.timeline-event-copy strong {
  font-weight: 700;
}
.timeline-event-copy p:not(.timeline-event-thumbnail) {
  padding-bottom: 1.2em;
}
.timeline-event-icon {
  -moz-transition: -moz-transform 0.2s ease-in;
  -o-transition: -o-transform 0.2s ease-in;
  -webkit-transition: -webkit-transform 0.2s ease-in;
  transition: transform 0.2s ease-in;
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  background-color: #74c36d;
  outline: 4px solid rgb(77 160 69 / 20%);
  display: block;
  margin: 0.5em 0.5em 0.5em -0.5em;
  position: absolute;
  top: 0;
  left: 5.3em;
  width: 0.7em;
  height: 0.7em;
}
.timeline-event-thumbnail {
  display: none !important;
  -moz-transition: box-shadow 0.5s ease-in 0.1s;
  -o-transition: box-shadow 0.5s ease-in 0.1s;
  -webkit-transition: box-shadow 0.5s ease-in;
  transition: box-shadow 0.5s ease-in 0.1s;
  color: white;
  font-size: 0.75em;
  background-color: #a6a6a6;
  -moz-box-shadow: inset 0 0 0 0em #bed904;
  -webkit-box-shadow: inset 0 0 0 0em #bed904;
  box-shadow: inset 0 0 0 0em #bed904;
  display: flex;
  padding: 0.25em 1em 0.2em 1em;
  align-items: center;
  justify-content: center;
}
.timeline-event-copy p {
  font-size: 22px;
}
.form {
  text-align: center;
  display: flex;
  flex-direction: row;
  padding: 20px 0;
}
select#loc {
  padding: 0.7em 1em;
  border-radius: 4px;
  color: #333;
  border-color: #eaeaea;
  box-shadow: 0px 2px 4px #ccc;
  margin-left: 10px;
}
/* .trace {
    padding: 60px 20px 40px;
} */
.trace .footprints-img {
  width: 28px;
  height: 28px;
  margin: 0 15px 3px 0;
}
.trace h1,
.more h1 {
  color: #4da045;
  animation: text 2.5s 1;
  font-size: 4rem;
  margin-bottom: 40px;
  letter-spacing: -0.02em;
  /* line-height: 5rem; */
  /* font-weight: 600; */
}
.trace h3 {
  /* font-weight: 600; */
  color: #333;
}
.timeline-date {
  width: 20%;
}
.timeline-date span {
  font-size: 22px;
}
/*More css*/
#moreSection {
  padding: 60px 20px 100px;
  background-image: url("/public/images/background/assurance-mobile.png");
  background-repeat: no-repeat;
  background-size: cover;
  /* background: linear-gradient(rgb(241 241 241), #f6f7d4); */
  /* background: #3b3b3b; */
}
#moreSection a.nav-link {
  font-size: 17px;
}
#moreSection a.active.nav-link {
  background: #4da045 !important;
  border-radius: 0;
  color: #fff !important;
}
.active-icon {
  display: none;
}
#moreSection a.active.nav-link .normal-icon {
  display: none;
}
#moreSection a.active.nav-link .active-icon {
  display: block;
  float: left;
  margin-right: 6px;
}
.more .assurance-img {
  width: 24px;
  height: 24px;
  margin: 0 20px 5px 0;
}
.assurance-tab ul {
  list-style: none;
  padding: 40px 20px;
}
.detail-box {
  background: #fff;
  /* box-shadow: 2px 2px 10px #e2e2e2; */
  padding-left: 0 !important;
  padding-right: 0 !important;
  margin-top: 20px !important;
  border: 15px solid #3b3b3b;
}
ul.nav.nav-tabs {
  /* border: 0; */
  /* box-shadow: 1px 1px 0px #e6e6e6; */
  justify-content: center;
}
a.facebook.nav-link {
  position: relative;
  right: -20px;
}
.more .nav-link img {
  width: 20px;
  float: left;
  margin-right: 6px;
  margin-top: 3px;
}
svg.svg-inline--fa.fa-lightbulb {
  color: #d4c70f;
  margin: 0 10px 0 3px;
}
.dir h5.modal-title {
  display: flex;
  align-items: center;
}
.dir-icon {
  cursor: pointer;
  color: #707070;
  font-size: 16px;
}
.modal-body,
.modal-header {
  color: #6d6d6d !important;
}
ul.dir-list {
  padding: 5px 10px;
}
.dir-list li {
  margin: 0 0 2px 12px;
  padding-left: 10px;
}
.dir-icon svg {
  margin-right: 4px;
}
button.main-btn {
  background: #4da045;
  border: 0;
}
button.main-btn:hover {
  background: #0c743b;
}
ul.dir-list {
  list-style: number;
}
.explorer {
  font-size: 15px;
  margin-bottom: 31px;
}
.modal.show .modal-dialog {
  top: 10%;
}
.timeline-event-copy b {
  font-weight: 500;
  color: #000 !important;
}
.claim-modal,
.verified-modal p {
  font-size: 16px;
}
.verified-modal img {
  width: 140px;
}
.verified-modal svg {
  color: #ffd500;
  margin-right: 5px;
}
.verified-modal span {
  font-size: 16px;
  color: #6d6d6d;
}
.warning {
  background: #ff8c82;
  /* text-align: center; */
  align-items: center;
  display: flex;
  /* justify-content: center; */
  margin-top: 40px;
  padding: 20px 22px 12px;
  line-height: 20px;
  margin-bottom: 0;
  font-size: 16px;
  cursor: pointer;
}
.warning svg {
  color: #fad271;
  margin-right: 18px;
  height: 28px;
  width: 28px;
}
.warning p {
  margin-bottom: 0;
}
.item-verified {
  margin-top: 30px;
  padding: 10px 30px 6px;
  background: rgb(128, 182, 93);
  color: #fff;
}
.item-verified span {
  font-size: 18px;
}
.item-verified img {
  width: 19px;
  height: 19px;
  margin-right: 15px;
}
.item-verified .dir-icon {
  margin-left: 18px;
  color: rgb(12, 83, 64);
}
.claim {
  /* text-align: center; */
  padding: 15px 30px 10px !important;
  background: rgb(128, 182, 93);
  margin-top: 30px;
}
.claim h1 {
  color: #4da045;
  font-weight: 500;
  animation: text 2.5s 1;
  font-size: 2rem;
  line-height: 2rem;
}
.claim button {
  background: #fff;
  border: 2px solid #4da045;
  color: #4da045;
  border-radius: 20px;
  padding: 8px 30px;
}
.claim button:hover {
  border: 2px solid #4da045;
  background: #2b8522;
  color: #fff;
}
.claim .dir-icon {
  display: block;
  text-align: center;
  margin: 8px 0 0 15px;
  color: rgb(12, 83, 64);
}
.scan-count {
  color: grey;
  width: 20px;
  margin-left: 20px;
  position: relative;
  margin-right: 5px;
}
span.count-number {
  position: absolute;
  font-size: 18px;
  margin-left: 8px;
  color: #333;
}
.certificate-border-placeholder {
  border: 1px solid #ccc;
  border-radius: 10px;
  display: flex;
  align-items: center;
  padding: 5px 20px 5px 5px;
  margin: 8px 0;
}
.certificate {
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 15px;
  width: 100%;
  align-items: center;
  display: flex;
}
.certificate img {
  /* height: 50px; */
  padding: 5px 5px 5px 0;
  object-fit: contain;
  object-position: center;
}
.certificate span {
  line-height: 18px;
  font-size: 16px;
  text-align: left;
  color: #333;
  display: flex;
  align-items: center;
  width: 100%;
  overflow: auto;
  margin-left: 5px;
}
.certificate-section {
  padding: 40px 20px 40px;
}
.certificate-section.row {
  width: 70%;
  margin: 0 auto;
}
.certificate-section .col {
  display: flex;
  justify-content: center;
}
.certificate-section a {
  width: 100%;
  display: flex;
  flex-direction: row;
  text-decoration: none;
}
.external-link-icon,
.zoom-in-icon {
  margin-left: 4px;
}
.certificate-section .assurance-tab {
  display: flex;
  width: 100%;
  justify-content: center;
  flex-direction: column;
}

.certificate-action-btn {
  display: flex;
  width: 50%;
  min-width: 100px;
  margin: 0;
}
.certificate-action-btn a {
  color: gray;
  font-size: 11.5px;
  width: auto;
  text-decoration: underline;
  display: flex;
  align-items: center;
}
.environment div {
  margin-bottom: 10px;
  border-bottom: 1px solid #ccc;
  padding: 0px 10px 10px 10px;
  display: flex;
  flex-direction: column;
}
.environment p {
  margin-bottom: 0;
  text-align: left;
}
.environment span {
  float: right;
}
ul.environment {
  width: 70%;
  margin: 0 auto;
}
.trace .timeline li:first-child,
.trace .timeline li:first-child .timeline-event-copy p,
.trace .timeline li:first-child .timeline-event-copy b {
  color: #4da045 !important;
}
.trace .batch-button,
.product .batch-button {
  background: #fff;
  color: #5e6064;
  border: 1px solid rgb(235, 235, 235);
  border-radius: 20px;
  margin-bottom: 8px;
  padding: 4px 24px;
  font-size: 16px;
}
.trace .batch-button img,
.product .batch-button img {
  width: 20px;
  height: 20px;
  margin-left: 8px;
}
.product p {
  color: #5e6064;
  font-size: 16px;
  margin-bottom: 7px;
}
.product-badges {
  display: flex;
  gap: 12px;
  flex-wrap: nowrap;
  overflow-x: auto;
  /* overflow-y: hidden; */
  padding: 2px 5px 8px;
}
.product-badges .card {
  display: flex;
  flex: 0 0 auto;
  justify-content: center;
  width: 80px;
  height: 80px;
  border: none;
  border-radius: 12px;
  box-shadow: rgb(64 64 64 / 24%) 0px 3px 7px;
  padding: 6px;
}
.product-badges .card img {
  object-fit: contain;
  object-position: center;
}
.product-details .card {
  margin: 8px 0 22px 0;
  border: none;
  border-radius: 12px;
  box-shadow: rgb(64 64 64 / 24%) 0px 3px 7px;
}
.product-details ul {
  list-style: none;
  text-align: center;
  padding: 10px;
  margin-bottom: 0;
}
.product-details ul:empty {
  display: none;
}
.product-details li {
  color: #5e6064;
  font-size: 16px;
  letter-spacing: -0.03em;
}
/* .product-description img {
    height: 50px;
} */
.tagged img {
  height: 80px;
}
.claim-modal input {
  border: 1px solid #4da045;
  border-radius: 30px;
  padding: 10px;
  margin: 10px auto;
  width: 90%;
}
.claim-modal ul {
  list-style-type: number;
  font-size: 16px;
}
.claim-button {
  background: #fff !important;
  border: 2px solid #4da045 !important;
  color: #4da045 !important;
  border-radius: 20px !important;
  padding: 8px 30px !important;
  margin: 20px auto;
  display: block !important;
}
.claim-button:hover {
  background: #4da045 !important;
  color: #fff !important;
}
.claim-modal .modal-footer button {
  padding: 8px 25px;
  font-size: 16px;
}
.claim-modal .modal-footer button:hover {
  background-color: #2b8522;
}
.claim-modal .modal-title,
.verified-modal .modal-title {
  text-align: center;
  display: block;
  width: 100%;
  color: #4da045;
}
.verified-modal .header-wrapper {
  padding: 15px 12px 15px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.verified-modal .modal-footer button {
  padding: 8px 25px;
}
.verified-modal .modal-footer button:hover {
  background-color: #2b8522;
}
.verified-modal .claimed {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.verified-modal .claimed .claimed-img {
  width: 100px;
  margin-right: 20px;
}
.verified-modal .claimed div {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.verified-modal .claimed-tooltip span {
  font-size: 15px;
  cursor: pointer;
  color: #8c8c8c;
}
.verified-modal .claimed-tooltip .claimed-icon {
  color: #8c8c8c;
}
/* .claim-info {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.claim-info img {
    margin-right: 10px;
}
.claim-info div {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
} */
.modal-footer {
  border-top: 0 !important;
}
.modal-header {
  border-bottom: 0 !important;
  padding-top: 40px !important;
}
.ingredients {
  color: #fff;
  margin-top: 40px;
  text-align: center;
}
.ingredients p {
  color: #4da045;
  letter-spacing: -0.02em;
  font-size: 27px;
}
.ingredients .ingredients-img {
  width: 25px;
  height: 25px;
  margin-right: 10px;
}
.ingredients ul {
  list-style-type: none;
  padding-left: 0;
}
.ingredients .ingredients-button {
  border: none;
  box-shadow: rgb(28 28 28 / 24%) 0px 5px 10px;
  /* cursor: default; */
}
#traceSection h6 {
  font-size: 20px;
}
.marketing-placeholder {
  padding: 70px 0 0 0 !important;
}

/*Footer*/
#footerSection {
  text-align: center;
  padding: 20px;
  background-color: #e9f1e6;
}
.footer .footer-logo {
  width: 200px;
  height: 22px;
  margin-top: 3px;
}
.footer span {
  font-size: 13px;
  color: #639d50;
}
.footer p {
  font-size: 11px;
  color: #639d50;
}
.footer a {
  color: #639d50;
  text-decoration: none;
}
.footer a:hover {
  color: #2b8522;
}
.footer .link-logo,
.footer .linkedin-logo {
  color: #000;
  width: 22px;
  height: 22px;
}

/*Responsive*/
@media only screen and (max-width: 1200px) {
}
@media only screen and (max-width: 1024px) {
  .timeline-date {
    width: 25%;
  }
}
@media only screen and (max-width: 990px) {
  .certificate-section.row {
    width: 100%;
    margin: 0 auto;
  }
  .timeline {
    left: 0;
  }
  .banner h1 {
    font-size: 3rem;
  }
  .banner h6 {
    font-size: 18px;
  }
  .info h1,
  .trace h1,
  .more h1 {
    /* line-height: 3rem; */
    font-size: 3rem;
  }
  .timeline-event-copy {
    width: 80%;
  }
  /* .navbar > .container-fluid {
        flex-direction: column;
    } */
  .navbar .navbar-nav {
    flex-direction: row;
    width: 100%;
    justify-content: space-evenly;
    /* margin-top: 34px; */
    /* margin-bottom: -10px; */
  }
  /* ul.right-nav.nav {
        position: absolute;
        right: 0;
    } */
  a.navbar-brand {
    position: absolute;
    left: 10px;
    width: 170px;
  }
  .navbar li.nav-item {
    width: auto;
    margin: 0 10px;
  }
  /* .right-nav li.nav-item {
        margin: 0;
    } */
  .timeline-date {
    width: 38%;
  }
  .productImage .organisation-logo {
    width: 55px;
    height: 55px;
    left: 25px;
  }
}
@media only screen and (max-width: 768px) {
  .detail-box li.nav-item {
    width: 100%;
  }
  .more .nav-link img,
  #moreSection a.active.nav-link .active-icon {
    margin-right: 0;
  }
  #moreSection a.active.nav-link .active-icon {
    display: inline-block;
  }
  ul.environment {
    width: 100%;
    margin: 0 auto;
  }
  .certificate-section .assurance-tab {
    display: block;
  }
  /* .certificate-section a {
    margin-bottom: 2%;
  } */
  .warning {
    padding-top: 12px;
  }
  .more .nav-link img {
    margin-top: 0;
    width: 18px;
  }
  .claim {
    text-align: left;
  }
  .explorer {
    text-align: center;
  }
  .timeline-event-copy p {
    text-align: left !important;
  }
  .info h1,
  .trace h1,
  .more h1 {
    /* line-height: 2rem; */
    font-size: 30px;
    text-align: center;
  }
  .trace h3,
  .trace h6 {
    text-align: center;
  }
  a.nav-link {
    font-size: 14px;
  }
  .navbar li.nav-item {
    width: auto;
    margin: 0 8px;
  }
  .logo {
    width: 180px;
  }
  .product h2 {
    font-size: 24px;
    padding-top: 20px;
  }
  .banner h1 {
    font-size: 2rem;
    /* animation: none; */
  }
  .banner h6 {
    font-size: 16px;
  }
  .purchase-btn {
    font-size: 16px !important;
  }
  .btn_group .btn {
    font-size: 16px;
  }
  .info img {
    max-width: 100%;
  }
  .timeline-event-thumbnail {
    background: #4da045;
  }
  .timeline-event-copy {
    width: 60%;
  }
  .timeline-event {
    justify-content: center;
  }
  .timeline-event-copy p,
  .timeline-date span {
    font-size: 16px;
  }
  .timeline-date {
    width: 30%;
  }
  .timeline-event-icon {
    left: 5.7em;
  }
  .timeline:before {
    left: 100px;
  }
  #moreSection a.nav-link {
    font-size: 16px;
  }
  .verified-modal .claimed .claimed-img {
    width: 90px;
  }
  .certificate {
    flex-direction: column;
    padding-bottom: 5px;
  }
  .certificate-action-btn {
    justify-content: space-between;
    width: 100%;
    margin-top: 5px;
  }
}
@media only screen and (max-width: 375px) {
  .banner h1 {
    font-size: 30px;
  }
  .banner h6 {
    font-size: 15px;
  }
  .claim button {
    padding: 6px 22px;
    font-size: 15px;
  }
  .item-verified span {
    font-size: 17px;
  }
  .item-verified img {
    width: 18px;
    height: 18px;
  }
  .verified-modal .claimed .claimed-img {
    width: 80px;
  }
  .timeline-event-icon {
    left: 5em;
  }
  .timeline:before {
    left: 87px;
  }
  .productImage .organisation-logo {
    width: 50px;
    height: 50px;
    left: 15px;
  }
  .purchase-btn {
    font-size: 15px !important;
  }
  .btn_group .btn {
    padding: 10px 6px;
    font-size: 15px;
  }
  .info .youtube-video {
    height: 200px;
  }
  .info .gallery-active-img img {
    height: 200px;
  }
}

@media only screen and (max-width: 349px) {
  .banner h1 {
    font-size: 25px;
  }
  .navbar li.nav-item {
    margin: 0 6px;
  }

  .timeline-event-icon {
    left: 4.4em;
  }
  .timeline:before {
    left: 77px;
  }
  .timeline-date {
    width: 30%;
  }
  .cart-icon,
  .ar-icon,
  .immersive-icon {
    font-size: 18px;
  }
  .purchase-btn {
    font-size: 14px !important;
  }
  .btn_group .btn {
    padding: 10px 3px;
    font-size: 14px;
  }
  .navbar .container-fluid {
    padding: 0 5px;
  }
}

@media only screen and (min-width: 1220px) {
  #traceSection {
    padding: 180px 20px 120px !important;
  }
  #moreSection {
    padding: 160px 20px 150px !important;
  }
}
@media only screen and (min-width: 1024px) {
  .claim button {
    font-size: 17px;
  }
  .info .youtube-video {
    height: 300px;
  }
  .info .gallery-active-img img {
    height: 300px;
  }
}
@media only screen and (min-width: 990px) {
  .trace .footprints-img {
    width: 62px !important;
    height: 62px !important;
  }
  .more .assurance-img {
    width: 43px !important;
    height: 43px !important;
    margin-bottom: 10px !important;
  }
  .certificate-section .assurance-tab {
    width: 450px;
  }
  .info .info-img {
    width: 42px !important;
    height: 42px !important;
  }
  .footer .footer-logo {
    width: 250px;
    height: 28px;
    margin: 5px 0 10px;
  }
  .footer span {
    font-size: 15px;
    padding-top: 10px;
  }
  .footer p {
    font-size: 13px;
  }
  .footer a svg {
    width: 30px;
    height: 30px;
  }
}
@media only screen and (min-width: 768px) {
  #productSection {
    background-image: url("/public/images/background/product-web.png");
  }
  #infoSection {
    background-image: url("/public/images/background/info-web.png");
    padding: 150px 20px 100px;
  }
  #traceSection {
    background-image: url("/public/images/background/footprint-web.png");
    padding: 150px 20px 100px;
  }
  #moreSection {
    background-image: url("/public/images/background/assurance-web.png");
    padding: 150px 20px 120px;
  }
  .claim {
    padding: 20px 30px 10px !important;
  }
  .item-verified {
    margin-top: 38px;
    padding: 10px 30px 8px;
  }
  .trace .footprints-img {
    width: 45px;
    height: 45px;
  }
  .more .assurance-img {
    width: 35px;
    height: 35px;
    margin-bottom: 8px;
  }
  .info .info-img {
    width: 35px;
    height: 35px;
    margin-bottom: 7px;
  }
  #fullProductSection {
    background-color: #f2faf7;
  }
  #productImageSection {
    padding-right: 5px;
  }
  #productSection {
    background-image: none !important;
    background-color: #f2faf7;
  }
  .certificate-action-btn {
    flex-direction: column;
    align-items: flex-end;
  }
}
/* @media only screen and (min-width: 576px) {
  #productSection {
    background-image: url("/public/images/background/product-web.png");
  }
  #infoSection {
    background-image: url("/public/images/background/info-web.png");
  }
  #traceSection {
    background-image: url("/public/images/background/footprint-web.png");
  }
  #moreSection {
    background-image: url("/public/images/background/assurance-web.png");
  }
} */
